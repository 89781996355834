import { User } from '@XUND/auth'
import axios, { AxiosError, AxiosInstance } from 'axios'
import { getEnvironment } from '../../hooks/useEnvironmentVariables'
import { notify } from '../eventbus'

export const getAuthenticationHeader = () => ({
  apiKey: getMedicalBackendApiKey(),
  // const headers: any = {}
  // headers.token = AuthTokenStore.getVariable()
  // headers.language = 'en' // ToDo: From User // currentDataLanguage()
  // headers.apiKey = getMedicalBackendApiKey()
  // return headers
})

/**
 * @returns The API Base URL
 */
export function getBaseUrl() {
  return getEnvironment().baseUrl
}

/**
 * @returns The CMS service URL
 */
export function getCMSBaseUrl() {
  return getEnvironment().cmsUrl
}

/**
 * @returns The CMS API Key
 */
export function getCMSApiKey() {
  return getEnvironment().cmsApiKey
}

/**
 * @returns The Medical Backend API Key
 */
export function getMedicalBackendApiKey() {
  return getEnvironment().medicalBackendApiKey
}

let instance: AxiosInstance

/**
 * @returns The Axios instance for API Fetching
 */
function getAxiosInstance() {
  if (!instance) {
    instance = axios.create({
      baseURL: getBaseUrl(),
      timeout: 60000,
      headers: getAuthenticationHeader(),
    })
  }

  // instance.interceptors.request.use(
  //   // (config) => {
  //   //   config.headers = getAuthenticationHeader()
  //   //   return config
  //   // },
  //   (error) => {
  //     return Promise.reject(error)
  //   },
  // )

  return instance
}

export const sendWebAdminPostRequest = <T>(
  uri: string,
  user: User,
  body?: any,
  queryParameters?: unknown,
): Promise<T> => {
  const baseUrl = getBaseUrl().replace('wadmin', 'webAdmin')
  return post(baseUrl + uri, user, body, queryParameters)
}

export const post = function post<T>(uri: string, user: User, body?: any, queryParameters?: unknown): Promise<T> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .post(uri, body, {
        params: queryParameters,
        headers: {
          language: user?.dataLanguage,
        },
        withCredentials: true,
      })
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          notify('logout')
        }
        reject(error)
      })
  })
}

export const sendWebAdminPatchRequest = <T>(uri: string, user: User, body?: any): Promise<T> => {
  const baseUrl = getBaseUrl().replace('wadmin', 'webAdmin')
  return patch(baseUrl + uri, user, body)
}

export const patch = function patch<T>(uri: string, user: User, body?: any): Promise<T> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .patch(uri, body, { withCredentials: true, headers: { language: user?.dataLanguage } })
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          notify('logout')
        }
        reject(error)
      })
  })
}

export const sendWebAdminGetRequest = <T>(uri: string, user: User, params?: Object): Promise<T> => {
  const baseUrl = getBaseUrl().replace('wadmin', 'webAdmin')
  return get(baseUrl + uri, user, params)
}

export const get = function get<T>(uri: string, user: User, params?: Object): Promise<T> {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .get(uri, {
        params,
        withCredentials: true,
        headers: { language: user?.dataLanguage },
      })
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          notify('logout')
        }
        reject(error)
      })
  })
}

export const deleteReq = (uri: string, user: User, data?: Object) => {
  if (data) {
    console.warn('Delete request body should be removed')
  }
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .delete(uri, {
        data,
        withCredentials: true,
        headers: { language: user?.dataLanguage },
      })
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          notify('logout')
        }
        reject(error)
      })
  })
}

export const download = async (uri: string, filename: Function) => {
  return new Promise((resolve, reject) => {
    getAxiosInstance()
      .get(uri, {
        responseType: 'blob',
        withCredentials: true,
      })
      .then((result) => {
        console.log(result)
        const url = window.URL.createObjectURL(new Blob([result.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename())
        document.body.appendChild(link)
        link.click()
      })
      .catch((error) => {
        reject(error)
      })
  })
}

/**
 * Use this function to get the description from an AxiosError object
 *
 * @param axiosError Error to process. This should include the error response
 * @returns string that can be the description of an error popup and includes all available information
 */
function getNotificationDescription(axiosError: AxiosError): string | undefined {
  const standardErrorDescription =
    axiosError.response?.data?.detailedType || axiosError.response?.data?.details?.description
  const standardErrorCode = axiosError.response?.data?.details?.errorCode || axiosError.response?.data?.customType
  let description: string | undefined = undefined
  if (standardErrorDescription || standardErrorCode) {
    description = `${standardErrorDescription || ''} -- Error code: ${standardErrorCode || 'none'} --`
  } else if (axiosError.response) {
    description = JSON.stringify(axiosError.response)
  }
  return description
}

/**
 * Use this function at catches when communicationg with network to get the information to show in error notification.
 *
 * @param axiosError Error to process. This should include the error response
 * @returns Object that can directly passed as parameter to error notification view
 */
export function getErrorNotificationInfo(axiosError: AxiosError): { message: string; description?: string } {
  const description = getNotificationDescription(axiosError)
  const infoObject = {
    message: axiosError.message,
    description,
  }
  return infoObject
}
