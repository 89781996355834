export const events = [
  'loadSymptoms',
  'logout',
  'languageChanged',
  'constantSaved',
  'symptomFormValidation',
  'symptomGroupFormValidation',
  'illnessSaveCheck',
  'referenceValidation',
] as const

export type EventType = typeof events[number]

const listeners = new Map<EventType, Map<string, Function>>()

/**
 * @param event The Event Type
 * @param listenerClass Class name for the Listener
 * @param callback The Callback that will be called on event
 */
export function addListener(event: EventType, listenerClass: string, callback: Function) {
  if (!listeners.has(event)) {
    const callbacks = new Map<string, Function>()
    callbacks.set(listenerClass, callback)
    listeners.set(event, callbacks)
  } else {
    const callbacks: Map<string, Function> = listeners.get(event)
    callbacks.set(listenerClass, callback)
    listeners.set(event, callbacks)
  }
}

/**
 * @param event The Event Type
 * @param listenerClass The Listener Class
 */
export function removeListener(event: EventType, listenerClass: string) {
  if (listeners.has(event)) {
    const callbacks: Map<string, Function> = listeners.get(event)
    if (callbacks.has(listenerClass)) {
      callbacks.delete(listenerClass)
    }
  }
}

/**
 * @param event The Event Type
 * @param data The Data object
 */
export function notify(event: EventType, data?: any) {
  if (listeners.has(event)) {
    const callbacks: Map<string, Function> = listeners.get(event)
    callbacks.forEach((callback) => {
      callback(data)
    })
  }
}

/**
 * @param event The Event type
 * @returns if the Event Type is valid
 */
export async function validate(event: EventType) {
  if (listeners.has(event)) {
    const callbacks: Map<string, Function> = listeners.get(event)
    let successful = true
    const functions = Array.from(callbacks.values())
    for (const callback of functions) {
      const success = await callback()
      console.log({ success2: success })
      if (!success) {
        successful = false
      }
    }

    return successful
  } else {
    return true
  }
}

export default {
  addListener,
  removeListener,
  notify,
  events,
  validate,
}
