import { LockOutlined } from '@ant-design/icons'
import { Button, Col, Layout, Row, Tabs } from 'antd'
import moment from 'moment'
import React from 'react'
import { EnvironmentIndicator } from '../components/EnvironmentIndicator'
import { currentEnvironment } from '../environmentIndicatorConfigs'
import { getEnvironment } from '../hooks/useEnvironmentVariables'
import logo from '../images/logo_login.png'

const { Content, Footer } = Layout

export const LoginPage: React.FC = () => {
  return (
    <Layout className="layout" style={{ height: '100%' }}>
      <Content>
        <Row justify="space-around" style={{ marginTop: 200 }}>
          <Col xs={8} sm={6} lg={4} style={{ textAlign: 'center' }}>
            <img src={logo} style={{ maxWidth: '60%', marginBottom: 20 }} />

            {currentEnvironment && <EnvironmentIndicator isLogin />}

            <Tabs defaultActiveKey="1" style={{ marginBottom: '1em' }} centered>
              <Tabs.TabPane tab="XUND account" key="2">
                <a
                  href={`${getEnvironment().businessAdminUrl}/login/oauth/${
                    getEnvironment().businessAdminClientId
                  }?callbackUrl=${encodeURIComponent(`${location.pathname}${location.search}`)}`}>
                  <Button icon={<LockOutlined />} type="primary" id="login_button">
                    Log In
                  </Button>
                </a>
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </Content>
      <Footer className="footer">© {moment().year()} XUND Solutions GmbH, Webgasse 9/2.8, 1060 Vienna</Footer>
    </Layout>
  )
}
