import { notification } from 'antd'
import React, { useContext, useState } from 'react'

export class DevModeContext {
  private readonly localStorageKey = 'XundWebadminDevMode'
  isEnabled = localStorage.getItem(this.localStorageKey) === 'true'
  private currentValue = ''
  private updateTimeout?: ReturnType<typeof setTimeout>
  private readonly listener = ((ev: KeyboardEvent) => {
    if (!(ev.target instanceof HTMLInputElement) && new RegExp(/[a-zA-z0-9]/gi).test(ev.key)) {
      this.currentValue += ev.key
      clearTimeout(this.updateTimeout)
      this.updateTimeout = setTimeout(() => {
        if (this.currentValue === 'iddqd') {
          notification.info({ message: this.isEnabled ? 'Developer mode disabled' : 'Developer mode enabled' })
          this.isEnabled = !this.isEnabled
          this.onChange?.(this.isEnabled)
          localStorage.setItem(this.localStorageKey, JSON.stringify(this.isEnabled))
        }
        this.currentValue = ''
      }, 500)
    }
  }).bind(this)

  public stopListener() {
    window.removeEventListener('keypress', this.listener)
  }
  public onChange?: (newValue: boolean) => void
  constructor() {
    window.addEventListener('keypress', this.listener)
  }
}

export const DevmodeContext = React.createContext(new DevModeContext())

export const useDevMode = () => {
  const ctx = useContext(DevmodeContext)
  const [isEnabled, setIsEnabled] = useState(ctx.isEnabled)
  ctx.onChange = (newValue) => setIsEnabled(newValue)

  return { isEnabled }
}
