import { ClientLanguage } from '@XUND/i18n'
import get from 'lodash/get'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/hu'
import { deepMerge } from '../utils/deepMerge'
import en from './en.json'
import de from './de.json'
import hu from './hu.json'
import ru from './ru.json'

import enCountries from './countries/en.json'
import huCountries from './countries/hu.json'

type TranslationMap = {
  language: string
  texts: Object
}

type CountryInfo = {
  language: string
  info: { [key: string]: string }
}

const availableCountryInfo: CountryInfo[] = [
  {
    language: 'en',
    info: enCountries,
  },
  {
    language: 'hu',
    info: huCountries,
  },
  {
    language: 'dev',
    info: {},
  },
]

const availableTranslations: TranslationMap[] = [
  {
    language: 'en',
    texts: en,
  },
  {
    language: 'de',
    texts: de,
  },
  {
    language: 'de-formal',
    texts: de,
  },
  {
    language: 'hu',
    texts: hu,
  },
  {
    language: 'ru',
    texts: ru,
  },
  {
    language: 'dev',
    texts: {},
  },
]
export const defaultLanguage = 'en'

let appLanguage = localStorage.getItem('language')
let dataLanguage = localStorage.getItem('dataLanguage')

if (!appLanguage || !dataLanguage) {
  localStorage.setItem('language', defaultLanguage)
  localStorage.setItem('dataLanguage', defaultLanguage)
  appLanguage = defaultLanguage
  dataLanguage = defaultLanguage
}
const languageIsAvailable = availableTranslations.some((t) => t.language === appLanguage)
if (!languageIsAvailable) {
  appLanguage = defaultLanguage
}
moment.locale(appLanguage)

export const availableLocales = (): ClientLanguage[] => {
  return availableTranslations.filter((t) => t.language !== 'dev').map((t) => t.language as ClientLanguage)
}

const getTranslation = (language: ClientLanguage, key: string, args: { [field: string]: string } = {}) => {
  try {
    let text =
      get(availableTranslations.find((translationmap) => translationmap.language === language).texts, key) ||
      get(availableTranslations.find((translationmap) => translationmap.language === 'en').texts, key)
    if (text !== undefined) {
      Object.keys(args).forEach((argKey: string) => {
        const value = args[argKey]
        const regexp = new RegExp(`{${argKey}}`)
        text = text.replace(regexp, value)
      })
      return text
    }
  } catch (error) {
    console.log(error)
  }

  return key
}

const i18n = getTranslation
export default i18n

export const getCountryName = (code: string) => {
  if (!code) {
    throw Error('No country code')
  }
  const countryInfo: CountryInfo = availableCountryInfo.find((c) => c.language === appLanguage)
  const countryName = countryInfo.info[code.toUpperCase()]
  if (!countryName) {
    return code
  }
  return countryName
}

export const getCountryData = (language: ClientLanguage) => {
  const data: any = availableCountryInfo.find((countryInfo) => countryInfo.language === language)
  if (data) {
    return data
  } else {
    return availableCountryInfo.find((countryInfo) => countryInfo.language === 'en')
  }
}

export const tableLocale = (language: ClientLanguage) => {
  return {
    filterConfirm: getTranslation(language, 'general.table.filter.confirm'),
    filterReset: getTranslation(language, 'general.table.filter.reset'),
    emptyText: getTranslation(language, 'general.table.filter.emptyText'),
  }
}

export const datePickerLocale = (language: ClientLanguage) => {
  return {
    lang: {
      placeholder: getTranslation(language, 'general.datePicker.placeholder'),
      rangePlaceholder: [
        getTranslation(language, 'general.datePicker.startDate'),
        getTranslation(language, 'general.datePicker.endDate'),
      ],
      today: getTranslation(language, 'general.datePicker.today'),
      now: getTranslation(language, 'general.datePicker.now'),
      ok: getTranslation(language, 'general.datePicker.ok'),
      clear: getTranslation(language, 'general.datePicker.clear'),
      timeSelect: getTranslation(language, 'general.datePicker.timeSelect'),
      dateSelect: getTranslation(language, 'general.datePicker.dateSelect'),
      dateTimeFormat: 'YYYY.MM.DD HH:mm',
      dateFormat: 'YYYY.MM.DD',
      yearFormat: 'YYYY',
      monthFormat: 'MMMM',
    },
  }
}

export const setLanguage = (language: string) => {
  localStorage.setItem('language', language)
}

export const setDataLanguage = (language: string) => {
  localStorage.setItem('dataLanguage', language)
}

export const useReplacer = (baseValue: string, args: { [K: string]: string }) => {
  Object.keys(args).forEach((argKey: string) => {
    const value = args[argKey]
    const regexp = new RegExp(`{${argKey}}`)
    baseValue = baseValue.replace(regexp, value)
  })
  return baseValue
}

const getFromKey = (key: string, obj: any, args?: { [K: string]: string }) => {
  const value = key.split('.').reduce((p, c) => (p && (p as any)[c]) || `!!${key}!!`, obj)
  // This is not a hook
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return args ? useReplacer(value, args) : value
}

const enExpanded = {
  ...en,
  useReplacer,
  getFromKey,
}

export const withTypes: {
  [K in ClientLanguage]: typeof en & {
    useReplacer: typeof useReplacer
    getFromKey: typeof getFromKey
  }
} = {
  en: enExpanded,
  de: deepMerge(enExpanded, de),
  hu: deepMerge(enExpanded, hu),
  ru: { ...enExpanded },
  'de-formal': deepMerge(enExpanded, de),
  it: { ...enExpanded },
}
