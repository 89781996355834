import { WebAdminApi } from '@XUND/rest-api-webadmin'
import { createAxiosClient } from '@XUND/rest-client-axios'
import { createContext, useContext } from 'react'
import { getEnvironment } from '../hooks/useEnvironmentVariables'
import { getAuthenticationHeader } from '../libs/utils/request'

export const axiosClientFactory = () =>
  createAxiosClient<WebAdminApi>({
    endpointUrl: getEnvironment().baseUrl,
    axiosOptions: {
      headers: getAuthenticationHeader(),
      withCredentials: true,
    },
  })

export const ApiContext = createContext(axiosClientFactory())

export const useWebAdminApi = () => useContext(ApiContext)
