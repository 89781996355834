import { Spin } from 'antd'
import React from 'react'

export const FullScreenSpinner = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'fixed',
      width: '100%',
      height: '100%',
    }}>
    <Spin size="large" delay={500} />
  </div>
)
