import { EnvironmentValidator } from '@XUND/service-utils'
import { KeysToSnakeCase } from '../utils/keysToSnakeCase'

window.xundEnvironment = window.xundEnvironment || {}

/**
 * Define the keys and types of env vars here (key names are automatically converted to SNAKE_CASE when reading from dotenv)
 *
 * @interface EnvironmentVariablesInCode
 */
export interface EnvironmentVariablesInCode {
  webadminBaseUrl: string
  baseUrl: string
  medicalBackendBaseUrl: string
  medicalBackendApiKey: string
  cmsUrl: string
  cmsApiKey: string
  stressTesterUrl: string
  stressTesterApiKey: string
  servicesUrl: string
  businessAdminUrl: string
  businessAdminClientId: string
}

type EnvironmentVariablesInDotenvKey = keyof KeysToSnakeCase<EnvironmentVariablesInCode>

export type EnvironmentVariablesInDotenv = Record<EnvironmentVariablesInDotenvKey, string>

/**
 * Get an env var from various sources, if not found, fall back to defaultValues
 *
 * @param envKey the key of the desired env var
 * @param defaultValue fallback value (as read from dotenv file) if env value not defined (empty string if not given)
 * @returns the env var in string
 */
export function getEnvWithDefault(envKey: EnvironmentVariablesInDotenvKey, defaultValue = ''): string {
  return window.xundEnvironment[envKey] || process.env[envKey] || defaultValue
}

/**
 * Checks if rawEnumValue string is in enum of specified type and if it is, return the value in the specified type
 *
 * @param acceptedEnumValues readonly array of accepted values the enum can have
 * @param enumDotenvKey display this dotenv key in case of validation error
 * @param rawEnumValue the value to check if it is in acceptedEnumValues
 * @returns rawEnumValue casted to the specified type
 */
export function validateAndCastDotenvEnum<T>(
  acceptedEnumValues: readonly T[],
  enumDotenvKey: EnvironmentVariablesInDotenvKey,
  rawEnumValue: string,
): T {
  const typedEnumValue = rawEnumValue as unknown as T
  if (!acceptedEnumValues.includes(typedEnumValue)) {
    throw new Error(`Invalid ${enumDotenvKey} environment variable - enum casting failed`)
  }
  return typedEnumValue
}

/**
 * Wrapper function to simplify reading and validating enum values from dotenv
 *
 * @param acceptedEnumValues readonly array of accepted values the enum can have
 * @param enumDotenvKey key of value in the dotenv file
 * @param defaultEnumValue fallback value if not given in the dotenv
 * @returns the validated dotenv value of the type specified in the generic parameter
 */
export function getAndValidateDotenvEnumValue<T>(
  acceptedEnumValues: readonly T[],
  enumDotenvKey: EnvironmentVariablesInDotenvKey,
  defaultEnumValue: T,
): T {
  return validateAndCastDotenvEnum(
    acceptedEnumValues,
    enumDotenvKey,
    getEnvWithDefault(enumDotenvKey, defaultEnumValue as unknown as string),
  )
}

/**
 * Hook to get environment variables, which are type-safe and validated
 *
 * @returns The Environment object
 */
export const getEnvironment = () => {
  // Define required env vars here
  const validator = new EnvironmentValidator(
    Object.keys(window.xundEnvironment).length > 0 ? window.xundEnvironment : process.env,
    [
      'WEBADMIN_BASE_URL',
      'BASE_URL',
      'MEDICAL_BACKEND_BASE_URL',
      'MEDICAL_BACKEND_API_KEY',
      'CMS_URL',
      'CMS_API_KEY',
      'SERVICES_URL',
      'BUSINESS_ADMIN_URL',
      'BUSINESS_ADMIN_CLIENT_ID',
    ] as EnvironmentVariablesInDotenvKey[],
  )
  if (!validator.isValid()) {
    throw new Error(`Missing environment variables: ${validator.getMissingKeys()}`)
  }

  /**
   * Convert dotenv values from string to the desired type (eg. string -> int with `parseInt()` or string -> boolean with `boolVar === true`)
   */
  return {
    webadminBaseUrl: getEnvWithDefault('WEBADMIN_BASE_URL'),
    baseUrl: getEnvWithDefault('BASE_URL'),
    medicalBackendBaseUrl: getEnvWithDefault('MEDICAL_BACKEND_BASE_URL'),
    medicalBackendApiKey: getEnvWithDefault('MEDICAL_BACKEND_API_KEY'),
    cmsUrl: getEnvWithDefault('CMS_URL'),
    cmsApiKey: getEnvWithDefault('CMS_API_KEY'),
    stressTesterUrl: getEnvWithDefault('STRESS_TESTER_URL'),
    stressTesterApiKey: getEnvWithDefault('STRESS_TESTER_API_KEY'),
    servicesUrl: getEnvWithDefault('SERVICES_URL'),
    businessAdminUrl: getEnvWithDefault('BUSINESS_ADMIN_URL'),
    businessAdminClientId: getEnvWithDefault('BUSINESS_ADMIN_CLIENT_ID'),
  }
}
