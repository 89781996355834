import React from 'react'
import { ExperimentOutlined, MonitorOutlined, SafetyOutlined } from '@ant-design/icons'

export const environmentIndicatorConfigs = [
  {
    env: 'Localhost',
    tagColor: 'green',
    textColor: 'green',
    loginTextColor: 'green',
    icon: <ExperimentOutlined />,
  },
  {
    env: 'Development',
    tagColor: 'blue',
    textColor: 'blue',
    loginTextColor: '#0065bd',
    icon: <MonitorOutlined />,
  },
  {
    env: 'Test',
    tagColor: 'blue',
    textColor: 'blue',
    loginTextColor: '#0065bd',
    icon: <MonitorOutlined />,
  },
  {
    env: 'Demo',
    tagColor: 'orange',
    textColor: '#da5d04',
    loginTextColor: '#da5d04',
    icon: <SafetyOutlined />,
  },
  {
    env: 'Beta',
    tagColor: '#00417a',
    textColor: '#ffffff',
    loginTextColor: '#00417a',
  },
  {
    env: 'Class1',
    tagColor: '#00417a',
    textColor: '#ffffff',
    loginTextColor: '#00417a',
  },
  {
    env: 'Live',
    tagColor: '#00417a',
    textColor: '#ffffff',
    loginTextColor: '#00417a',
  },
]

export const currentEnvironment = environmentIndicatorConfigs.find((item) =>
  window.location.href.includes(item.env.toLowerCase()),
)!
