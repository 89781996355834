import React from 'react'
import { Row, Tag, Typography } from 'antd'
import { currentEnvironment } from '../environmentIndicatorConfigs'

export const EnvironmentIndicator: React.FC<{ isLogin?: boolean }> = ({ isLogin }) => {
  if (isLogin) {
    return (
      <Typography.Title
        style={{ color: currentEnvironment.loginTextColor, marginBottom: 15, userSelect: 'none' }}
        level={4}>
        {currentEnvironment.env}
      </Typography.Title>
    )
  }

  return (
    <Row justify="center">
      <Tag
        color={currentEnvironment.tagColor}
        className="environmentIndicator"
        style={{ color: currentEnvironment.textColor }}>
        {currentEnvironment.icon} {currentEnvironment.env}
      </Tag>
    </Row>
  )
}
