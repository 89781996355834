import { User } from '@XUND/auth'
import { Button, Result } from 'antd'
import { AxiosError } from 'axios'
import moment from 'moment'
import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react'
import { FullScreenSpinner } from '../components/FullScreenSpinner'
import { ApiContext, axiosClientFactory } from '../context/apiContext'
import { useDevMode } from '../context/devModeContext'
import { UserContext } from '../context/userContext'
import { getEnvironment } from '../hooks/useEnvironmentVariables'
import { useI18N } from '../hooks/useI18n'
import { addListener, removeListener } from '../libs/eventbus'
import { setDataLanguage, setLanguage } from '../libs/i18n'
import { get, post } from '../libs/utils/request'
import { LoginPage } from '../pages/LoginPage'

const Routes = lazy(() => /* webpackChunkName:routes */ import('../Routes'))

export const MeHandler: React.FC = () => {
  useDevMode()
  const [user, setUser] = useState<User | null>(null)
  const [error, setError] = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const shouldTryOauthLogin = useCallback(() => {
    if (window.location.pathname === '/oauth-login' && new URLSearchParams(window.location.search).get('token')) {
      return true
    }
    return false
  }, [])

  const oauthLogin = useCallback(async () => {
    try {
      setIsLoading(true)
      const params = new URLSearchParams(window.location.search)
      const token = params.get('token')
      const callbackUrl = params.get('callbackUrl')
      const oauthLoginResult: { success?: boolean; user: User } = await post('/oauth-login', null, {
        clientId: getEnvironment().businessAdminClientId,
        token,
      })
      setIsLoggedIn(oauthLoginResult.success)
      setUser(oauthLoginResult.user)
      moment.locale(oauthLoginResult.user.language)
      window.history.pushState(null, '', callbackUrl)
    } catch (e) {
      setError(e)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const loadCurrentUser = useCallback(async () => {
    try {
      setIsLoading(true)
      const usr = await get<User>('/user/me', null)
      setUser(usr)
      moment.locale(usr.language)
      setDataLanguage(usr.dataLanguage)
      setLanguage(usr.language)
      setIsLoggedIn(true)
    } catch (err) {
      const axError = err as AxiosError
      if (axError.response?.status !== 401) {
        setError(err)
      }
      console.error(err)
      setUser(null)
      setIsLoggedIn(false)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if (shouldTryOauthLogin()) {
        await oauthLogin()
      } else {
        loadCurrentUser()
      }
    })()
  }, [loadCurrentUser, oauthLogin, shouldTryOauthLogin])

  useEffect(() => {
    const listener = async () => {
      await post('/logout', null)
      localStorage.removeItem('user')
      setIsLoggedIn(false)
    }
    addListener('logout', 'MeHandler', listener)
    return () => removeListener('logout', 'MeHandler')
  }, [])

  const i18n = useI18N()

  if (isLoading) {
    return <FullScreenSpinner />
  }

  if (error) {
    return (
      <Result
        title={i18n.login.error.unexpected}
        subTitle={error?.message}
        status={error?.response?.status || 'error'}
        extra={
          <Button type="primary" onClick={() => location.reload()}>
            Reload page
          </Button>
        }
      />
    )
  }

  if (!isLoggedIn) {
    return <LoginPage />
  }

  return (
    <UserContext.Provider value={user}>
      <ApiContext.Provider value={axiosClientFactory()}>
        <Suspense fallback={<FullScreenSpinner />}>
          <Routes />
        </Suspense>
      </ApiContext.Provider>
    </UserContext.Provider>
  )
}
